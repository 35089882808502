<template>
    <div>
        <v-app-bar id="inspire">
            <span  
                class="mdi mdi-menu icono-menu"
                @click="menuStart()"
              ></span>
            <div style="margin-left:40px">
                <h4>{{title}}</h4>
             </div>
            <v-spacer></v-spacer>
            <v-menu location="end">
                <template v-slot:activator="{ props }">
                    <v-btn color="white" v-bind="props">
                        <v-list-item-avatar>
                            <v-icon class="green--text large">mdi-account</v-icon>
                        </v-list-item-avatar>
                    </v-btn>
                </template>

                <v-card min-width="200">
                    <v-list>
                    <v-list-item
                        prepend-avatar="https://cdn.vuetifyjs.com/images/john.jpg"
                        title="Nombre Usuario"
                        subtitle="Subtitulo"
                    >
                    </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-list>
                        <center>
                            <v-btn color="transparent" large flat @click="funCloseSesion()">
                                Cerrar Sesión
                            </v-btn>
                        </center>
                    </v-list>
                </v-card>
            </v-menu>
        </v-app-bar>
    </div>
</template>


<script>
import {mapState, mapMutations} from 'vuex'
import {auth} from '../firebase.js'

export default {
  name: 'systembar',
  components: {},
  data() {
    return {
        user:{
            data:{
                name:"dieo"
            }
        },
        title:'DASHBOARD',
        fav: true,
        test:true,
        message: false,
        hints: true,
    };
  },
  methods:{
    ...mapMutations(['setMenuNavDraw','setIsMobileGlob']),
    funCloseSesion(){
        var self = this
        console.log("Cerrando Sesiuon")
        auth.signOut()
        .then((userCredential) => {
            console.log("cerrando sessuionmm x 1000")
            self.$router.push("/login");
        })
        .catch((error) => {
          var errorCode = error.code;
        });
    },
    funShowPnl(){
        console.log("Show User Panel")
    },
    menuStart(){
        this.setMenuNavDraw(!this.actMenuNavDraw)
    },
  },
  computed:{
    ...mapState(['actMenuNavDraw'])
  },
};
</script>