<template>
    <v-div class="full-height">
    <v-row align="center" justify="center" class="full-height">
      <v-col cols="12">
        <v-card outlined="false">
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-row >
                    <v-col cols="12" md="6">
                      <v-card-text class="mt-12">
                        <h2 class="text-center">Iniciar sesión en tu cuenta</h2>
                        <h3 class="text-center  grey--text ">Accede a tu cuenta para continuar personalizando 
                        <br>y ajustando tu agenda empresarial.</h3>
                        <v-row align="center" justify="center">
                          <v-col cols="12" sm="8"> 
                            <v-text-field
                              label="Email"
                              v-model="email"
                              outlined
                              dense
                              color="blue"
                              autocomplete="false"
                              class="mt-16"/>
                            <v-text-field
                              label="Password"
                              v-model="password"
                              outlined
                              dense
                              color="blue"
                              autocomplete="false"
                              type="password"/>
                           </v-col>
                           <v-col cols="12" sm="8" class="text-right">
                                <span  class="caption blue--text">
                                  <a href="#" @click="funRecoverPass()">Recuperar contraseña</a> 
                                </span>
                            </v-col>
                        </v-row>  
                        <v-row align="center" justify="center">
                          <v-col cols="12" sm="8"> 
                            
                            <v-btn @click="funLogIn()" color="blue" dark block tile>Iniciar Sesion</v-btn>
                      
                            <h5 class="text-center  grey--text mt-4 mb-3">O inicia sesion con</h5>
                            
                            <center>
                                <v-btn depressed outlined color="white">
                                  <v-icon size="large" @click="handleSignInGoogle" color="red darken-2">mdi-google-plus</v-icon>
                                </v-btn>
                            </center>
                          </v-col>
                        </v-row>

                      </v-card-text>
                    </v-col>
                    <v-col cols="12" md="6" class="blue rounded-bl-xl" >
                      <div style="  text-align: center; padding: 180px 0;">
                        <v-card-text class="white--text" >
                          <h3 class="text-center ">¿Todavía no tienes una cuenta?</h3>
                          <h6 class="text-center">Permítenos configurarte para que puedas comenzar a crear tu primera experiencia de incorporación.</h6>
                        </v-card-text>
                        <div class="text-center">
                          <v-btn tile outlined dark @click="funActSignUp()" >Registrar Cuenta</v-btn>
                        </div>
                      </div>
                    </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
  </v-div>
</template>

<script>
import {auth,providerGoogle,firebase,db} from "../firebase.js"
import Swal from 'sweetalert2';

export default {
   data: () => ({
    step: 1,
    email: '',
    password: '',
  }),
  props: {},
  methods:{
    funRecoverPass(){
      this.$router.push("/recover/password");
    },
    //Funcion to login user
    funLogIn(){
      var self = this
      if(this.email !="" && this.password != ""){
        var email = this.email
        var password = this.password
        auth.signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // Signed in
          var user = userCredential.user;
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          var errorCode = error.code;
          console.log("errorCode:",errorCode )
          if(errorCode == "auth/invalid-email"){
            Swal.fire({
              icon: "error",
              title: "Correo inválido",
              text: "Por favor introduce un correo electrónico válido.",
              theme: 'dark'
            });    
          }else if(errorCode == "auth/invalid-credential"){
            Swal.fire({
              icon: "error",
              title: "Credencial inválida",
              text: "La contraseña ingresada es inválida.",
              theme: 'dark'
            });  
          }
        });
      }else{
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Por favor completa todos los campos.",
          theme: 'dark'
        });

      }
    },
    //Funcion to show register panel
    funActSignUp(){
      this.$router.push("/signup");
    },
    //Funcion ti login with google service 
    handleSignInGoogle() {
      auth.signInWithPopup(providerGoogle)
        .then((result) => {
            var credential = result.credential;
            var token = credential.accessToken;
            var user = result.user;
            console.log("user...",user)
            db.collection("users").doc(user.uid).set({
                  name: user.displayName,
                  lastName: "",
                  email: user.email,
                  id: user.uid
              })
              .then((docRef) => {
                  this.$router.push("/dashboard");
              })
              .catch((error) => {
                  console.error("Error adding document: ", error);
              });
        }).catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            var email = error.email;
            var credential = error.credential;
            console.log("error:...", errorMessage)
        });
    },
  },
  computed:{},
  async mounted() {
    try {
        const currentUser = await firebase.getCurrentUser();
        if (currentUser) {
            this.$router.push("/dashboard");
        }
    } catch (error) {
        console.error("Error al obtener el usuario actual:", error);
    }
  }
}
</script>

<style scoped>
.v-application .rounded-bl-xl {
    border-bottom-left-radius: 300px !important;
}
.v-application .rounded-br-xl {
    border-bottom-right-radius: 300px !important;
}
.full-height {
  height: 100vh;
}
</style>