import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyDgwMQP6IS5kjgT_XYMbw28GZ8BWb7Xcyc",
    authDomain: "remindsmart-c37da.firebaseapp.com",
    projectId: "remindsmart-c37da",
    storageBucket: "remindsmart-c37da.appspot.com",
    messagingSenderId: "276167905099",
    appId: "1:276167905099:web:467d36b627b720505822ce",
    measurementId: "G-558FHQVSYC"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);


const auth = firebase.auth();
const providerGoogle = new firebase.auth.GoogleAuthProvider();
const db = firebase.firestore();
//const marcaTiempo = firebase.firestore.FieldValue.serverTimestamp;

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};


export {firebase, db, auth, providerGoogle};