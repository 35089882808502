<!--:expand-on-hover="$vuetify.breakpoint.mdAndUp":value="$vuetify.breakpoint.smAndDown ? drawer : true"-->
<template>
  <div id="App"> 
    <v-app id="inspire">
     
      <div>
        <!--<v-app-bar id="inspire" v-if="user != ''">
              <span 
                v-if="showIcon" 
                class="mdi mdi-menu icono-menu"
                @click="menuStart()"
              ></span>
              <div style="margin-left:50px">
                <h4>{{title}}</h4>
              </div>
              <v-spacer></v-spacer>
              <v-menu :close-on-content-click="false" :nudge-width="50" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-list-item-avatar>
                         <v-icon class="green--text">mdi-account</v-icon>
                      </v-list-item-avatar>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-list>
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon class="green--text">mdi-account</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title class="text-h6">
                              {{user.data.userName}}
                          </v-list-item-title>
                          <v-list-item-subtitle>VersionDate: 16-02-2024</v-list-item-subtitle>
                          <v-list-item-subtitle>Role:  {{user.data.role}}</v-list-item-subtitle>
                          <v-list-item-subtitle>nombre:  {{user.data.name}} {{user.data.fatherLastName}} {{user.data.motherLastName}}</v-list-item-subtitle>
                          </v-list-item-content>
                      </v-list-item>
                    </v-list>

                    <v-divider></v-divider>
                    
                    <v-list-item @click="logout()">
                      <v-list-item-icon>
                        <v-icon slot="prependIcon" 
                          >mdi-logout</v-icon>
                        </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title 
                          ><p>Cerrar Sesion</p></v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                      
                  </v-card>
                </v-menu>
        </v-app-bar>-->
            <router-view />
      </div>
    </v-app>
  </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import {mapState, mapMutations} from 'vuex'

export default {
  name: 'App',
  components: {
    Navbar
  },
  data: () => ({
    user:{
      data:{
        role:"admin",
        name:"Diego",
        userName:"diego.salgado",
        fatherLastName:"Salgado",
        motherLastName:"Giles",
      }
    },
    showAlert:false,
    drawer:true,
    title:"Dashboard",
    showIcon: false,
    menu:true,
  }),
  mounted() {
    this.checkWindowSize();
    window.addEventListener('resize', this.checkWindowSize);
  },
  methods: {
    ...mapMutations(['setMenuNavDraw']),
    menuStart(){
      this.setMenuNavDraw(!this.actMenuNavDraw)
    },
    checkWindowSize() {
      this.showIcon =  true//window.innerWidth <= 1024;
    },
    logout() {

    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkWindowSize);
  },
  computed:{
    ...mapState(['actMenuNavDraw'])
  },
  created() {
  },  
}
</script>

<style>
  body {
    margin: 0;
    padding: 0;
  }
  .icono-menu {
    font-size: 30px; /* Tamaño deseado del icono */
  }

.floating-alert {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 9999;
}
.custom-size {
  width: 300px;
  height: 1000px;
 
}
</style>
<style>
.custom-div {
    margin-left: 60px;
}
@media (max-width: 767px) {
    .custom-div {
        margin-left: 0; 
    }
}
</style>