import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import SignupView from '../views/SignupView.vue'
import DashboardView from '../views/DashboardView'
import RecoverPassView from '../views/RecoverPassView.vue'
import { firebase } from "../firebase";

const routes = [
  {
    path: '/',
    name: 'home',
    component: /* webpackChunkName: "homeView" */ DashboardView,
    meta: {requiresAuth: true,},
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: /* webpackChunkName: "homeView" */ DashboardView,
    meta: {requiresAuth: true,},
  },
  {
    path: '/login',
    name: 'login',
    component:/* webpackChunkName: "LoginView" */ LoginView,
  },
  {
    path: '/signup',
    name: 'signup',
    component:/* webpackChunkName: "SignupView" */ SignupView,
  },
  {
    path: '/recover/password',
    name: 'recoverPassword',
    component:/* webpackChunkName: "RecoverPassView" */ RecoverPassView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !(await firebase.getCurrentUser())) {
    next("/login");
  } else {
    next();
  }
});

export default router
