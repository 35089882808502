import { createStore } from 'vuex'

export default createStore({
  state: {
    actMenuNavDraw:false,
    actIsMobileGlob:false,
  },
  getters: {
  },
  mutations: {
    setMenuNavDraw(state,value){
      state.actMenuNavDraw = value
    },
    setIsMobileGlob(state,value){
      state.actIsMobileGlob = value
    },
  },
  actions: {
  },
  modules: {
  }
})
