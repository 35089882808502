<template>
    <v-navigation-drawer expand-on-hover 
        :rail="isMobile" 
        touchless="true" 
        mobile-breakpoint="xs"
        :temporary="!isMobile"
        v-model="actMenuNavDraw"
        @click.outside="hideDrawer"
        >
      <center>
        <v-list>
            <v-list-item class="px-2">
                <v-img
                src="../assets/logo.png"
                contain
                style="margin: 5px"
                width="50%"
                height="50%"/>
            </v-list-item>
        </v-list>
      </center>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item @click="title='Administrador de usuarios'">
          <v-list-item-icon>
            <v-icon size="large" color="green darken-2">mdi-domain</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Usuarios: {{ actMenuNavDraw }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
</template>


<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'navbar',
  components: {},
  data() {
    return {
        title:'',
        isMobile:false,
    };
  },
  methods:{
    ...mapMutations(['setMenuNavDraw','setIsMobileGlob']),
    hideDrawer(){
      if(!this.isMobile){
        this.setMenuNavDraw(false)
      } 
    },
    checkMobile() {
      this.isMobile = window.innerWidth >= 600; // Cambia el valor según el punto de quiebre móvil deseado
      if(this.isMobile){
        this.setMenuNavDraw(true)
      }
    }
  },
  computed:{
    ...mapState(['actMenuNavDraw'])
  },
  created() {
    window.addEventListener('resize', this.checkMobile)
    this.checkMobile()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile)
  }
};
</script>