<template>
    <v-div class="full-height">
    <v-row align="center" justify="center" class="full-height">
      <v-col cols="12">
        <v-card outlined="false">
            <v-window v-model="step">
              <v-window-item :value="1">
                  <v-row >
                    <v-col cols="12" md="6" class="blue rounded-br-xl">
                      <div style="  text-align: center; padding: 180px 0;">
                        <v-card-text class="white--text" >
                          <h3 class="text-center ">¿Ya te registraste?</h3>
                          <h6 class="text-center">Registrarse para una cuenta<br>  Editando tus flujos de incorporación</h6>
                        </v-card-text>
                        <div class="text-center">
                          <v-btn tile outlined dark @click="funActLogin()">Iniciar Sesion</v-btn>
                        </div>
                      </div>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-card-text class="mt-12">
                        <h2 class="text-center">Registra una cuenta</h2>
                        <h3 class="text-center  grey--text ">Vamos a configurarte para que puedas comenzar 
                        <br> a crear tu primera experiencia de incorporación.</h3>
                        <form @submit.prevent="crearUsuario({email: email, password: pass1})">
                        <v-row align="center" justify="center">
                          <v-col cols="12" sm="8">
                           <v-row>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                label="Nombre"
                                v-model="regName"
                                outlined
                                dense
                                color="blue"
                                autocomplete="false"
                                class="mt-4"/>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                label="Apellidos"
                                v-model="regLastName"
                                outlined
                                dense
                                color="blue"
                                autocomplete="false"
                              class="mt-4"
                              />
                              </v-col>
                           </v-row>
                            <v-text-field
                              label="Email"
                              v-model="emailRegister"
                              outlined
                              dense
                              color="blue"
                              autocomplete="false"
                            />
                            <v-text-field
                              label="Password"
                              outlined
                              dense
                              color="blue"
                              v-model="pass1Register"
                            autocomplete="false"
                            type="password"
                            
                            />
                            <v-text-field
                              label="Confirm Password"
                              outlined
                              dense
                              color="blue"
                              v-model="pass2Register"
                              autocomplete="false"
                              type="password"
                            />
                          <v-btn  @click="funRegisterUser()"  color="blue" dark block tile>Registrar</v-btn>
                         <h5 class="text-center  grey--text mt-4 mb-3">O registra usando</h5>
                         <center>
                            <v-btn depressed outlined color="white">
                              <v-icon size="large" @click="handleSignInGoogle" color="red darken-2">mdi-google-plus</v-icon>
                            </v-btn>
                          </center>
                          </v-col>
                        </v-row> 
                      </form> 
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
  </v-div>
</template>

<script>
import Swal from 'sweetalert2';
import {auth,providerGoogle,firebase,db} from "../firebase.js"

export default {
   data: () => ({
    step: 1,
    emailRegister: '',
    pass1Register: '',
    pass2Register: '',
    regName:'',
    regLastName:'',
  }),
  props: {},
  methods:{
    handleSignInGoogle() {
      auth.signInWithPopup(providerGoogle)
        .then((result) => {
            var credential = result.credential;
            var token = credential.accessToken;
            var user = result.user;
            console.log("user...",user)
            db.collection("users").doc(user.uid).set({
                  name: user.displayName,
                  lastName: "",
                  email: user.email,
                  id: user.uid
              })
              .then((docRef) => {
                  this.$router.push("/dashboard");
              })
              .catch((error) => {
                  console.error("Error adding document: ", error);
              });
        }).catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            var email = error.email;
            var credential = error.credential;
            console.log("error:...", errorMessage)
        });
    },
    //funcion para registrar usuario
    funRegisterUser(){
      var self = this
      if(this.pass1Register == this.pass2Register){
        var email = this.emailRegister
        var password = this.pass1Register
        auth.createUserWithEmailAndPassword(email, password)
          .then((userCredential) => {
              var user = userCredential.user;
              db.collection("users").doc(user.uid).set({
                  name: self.regName,
                  lastName: self.regLastName,
                  email: email,
                  id: user.uid
              })
              .then((docRef) => {
                  Swal.fire({
                      title: "¡Registro exitoso!",
                      text: "¡Felicidades! Tu registro ha sido completado con éxito.",
                      icon: "success"
                  });
                  this.$router.push("/dashboard");
              })
              .catch((error) => {
                  console.error("Error adding document: ", error);
              });
          })
          .catch((error) => {
            var errorCode = error.code;
            console.log("errorCode",errorCode)
            var errorMessage = error.message;
            if(errorCode == "auth/email-already-in-use"){
              Swal.fire({
                icon: "error",
                title: "¡Correo en uso!",
                text: "El correo electrónico ingresado ya está asociado a una cuenta.",
                theme: 'dark'
              });
            }

          });
      }else{
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Las contraseñas no coinciden!",
          theme: 'dark'
        });
      }
    },
    //Funcion to show the panel login[]
    funActLogin(){
      this.$router.push("/login");
    }
     
  },
  computed:{},
  async mounted() {
    try {
        const currentUser = await firebase.getCurrentUser();
        if (currentUser) {
            this.$router.push("/dashboard");
        }
    } catch (error) {
        console.error("Error al obtener el usuario actual:", error);
    }
  }
}
</script>
<style scoped>
.v-application .rounded-bl-xl {
    border-bottom-left-radius: 300px !important;
}
.v-application .rounded-br-xl {
    border-bottom-right-radius: 300px !important;
}
.full-height {
  height: 100vh;
}
</style>

