<template>
    <div>
        <v-container v-show="!actSendRecoverPass">
            <v-row>
                <v-col cols="1" xs="1" sm="3" md="3"  lg="4"></v-col>
                <v-col cols="10" xs="10" sm="6" md="6"  lg="4">
                    <center style="color:white">
                        <h3>¿Has olvidado tu contraseña?</h3>
                        <br>
                        <h4> Las instrucciones para recuperarla se enviarán por correo.</h4>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    label="Email"
                                    v-model="email"
                                    outlined
                                    dense
                                    color="blue"
                                    autocomplete="false"
                                    class="mt-16"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-btn  @click="sendRecoverPass()"  color="blue" dark block tile>Solicitar Restablecimiento</v-btn>
                            </v-col>
                        </v-row>
                    </center>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-show="actSendRecoverPass">
            <v-card class="mx-auto my-12" max-width="500" style="background-color: white; color:black">
                <center>
                    <v-card-item>
                        <v-icon
                            color="black"
                            icon="mdi-email-fast-outline"
                            style="font-size: 150px;"
                        ></v-icon>
                        <v-card-title>Recuperación de Contraseña</v-card-title>
                    </v-card-item>

                    <v-card-text>
                        <div>
                            <b>Enviamos un  correo electrónico a {{email }}, </b>
                            
                            <br>
                            Revisa tu bandeja de entrada y sigue las instrucciones
                            <br>
                            <br>
                            Si no recibes el correo electrónico en unos minutos, por favor revisa tu carpeta de correo no deseado.
                            <br>
                            <br>
                            ¿No recuerdas qué dirección de correo electrónico usaste para tu cuenta  o no estás seguro de qué cuenta estás asociado? No dudes en ponerte en contacto con nosotros.
                        </div>
                    </v-card-text>
                    <divider></divider>
                    <v-card-text>
                        <div>
                            ¿Tienes una cuenta? <a href="#" @click="toLogin()">Inicia sesión</a>
                        </div>
                    </v-card-text>
                </center>
            </v-card>
        </v-container>
    </div>
</template>
  
<script>
import {auth} from '../firebase.js'
import Swal from 'sweetalert2';

export default {
    name: 'funRecoverPassView',
    data: () => ({
        actSendRecoverPass: false,
        email:""
    }),
    components: {
    },
    methods:{
        toLogin(){
            this.$router.push("/login");
        },
        sendRecoverPass(){
            auth.sendPasswordResetEmail(this.email)
            .then(() => {
                this.actSendRecoverPass = true
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                Swal.fire({
                icon: "error",
                title: "Correo inválido",
                text: "Por favor introduce un correo electrónico válido.",
                theme: 'dark'
                });   
                // ..
            });
        }
    }
}
</script>
  